div.navigation-items{
  overflow: auto;white-space: nowrap;
}
div.navigation-items a {
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  padding: 10px;
  background-color: #fff;
  margin-left: 10px;
  margin-top: 8px;
  margin-bottom: 18px;
  box-shadow: 0 0 6px 0 rgb(121 121 121 / 40%);cursor:pointer;
}
body, html{
  margin:0;
  padding:0;
  overflow-x: hidden;
}
h1{
  font-size: 24px;
  font-weight: 600;
  display: inline;
}
h2{
  font-size: 19px;
  font-weight: 900;
  display: inline;
}
h3{
  font-size: 18.5px;
  font-weight: 900;
  display: inline;
}
.one{
  margin-top:15px;
  margin-bottom:30px;
  border-bottom: 1px solid lightgrey;
  border-top: 1px solid lightgrey;
  margin-left: 1%;
  margin-right: 1%;
  width: 98%;
}
.contact.col-sm-10, .address.col-sm-6{
  padding:0;
}
.phone-container, .email-container{
  display:inline;
  line-height:30px;
}
.phone-container{
  margin-left:30px;
}
.email-container{
  margin-left:40px;
}
.contact, .social-connect{
  height:30px;
}
.social-connect{
  text-align: center;
  line-height: 30px;
  border-left: 1px solid lightgrey;
}
.fa-phone, .fa-envelope, .fa-map-marked-alt, .fa-tag, .fa-user{
  font-size:18px;
  color:#0095ff;
}
.fa-chevron-circle-down{
  font-size:12px;
  color:#0095ff; 
}
.fa-facebook-f, .fa-instagram, .fa-linkedin, .fa-twitter, .fa-whatsapp, .fa-telegram{
  font-size:18px;
  margin-left:8px;
}
.fa-facebook-f{
  margin-left:20px;
}
.icon-holder{
  margin-right: 45px;
}
.number, .email{
  font-size:18px;
  font-family: 'Nunito', sans-serif;
}
.logo-img{
  margin-left:16px;
}
.menu ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 26px;
  margin-right: 36px;
  transform: translateY(-50%);
  font-size:18px;
  font-family: 'Nunito', sans-serif;
}
.menu li {
  display: inline;
  margin-left: 40px;
}
.logo-img img{
  width:250px;
}
.two{
  height: 150px;
  margin-left: 1%;
  margin-right: 1%;
  width: 98%;
}
.logo.col-sm-4, .menu.col-sm-8{
  height: 120px;
}
.three.col-sm-12, .six.col-sm-12{
  border-bottom: 1px solid lightgrey;
  width: 100%;
  background-color:rgba(92,136,197,.05);
  padding-top: 45px;
}
.six.col-sm-12{
  padding-bottom:45px;
}
.five.col-sm-12{
  padding-top: 45px;
  padding-bottom:45px;
}
.smallBox{
  width: 95%;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.seven.col-sm-12, .eight.col-sm-12{
margin:0;
padding:0;
}
.eight.col-sm-12{
margin-top:4%;
}
.nine.col-sm-12{
text-align:center;
}
.review-button{
  margin-top:45px;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: #000;
  display: inline-block;
  padding: 10px;
  border-radius: 4px;
  background-color: #fff;
}
.avt.col-sm-4{
text-align:center;
width: 1000px;
height: 1000px;
max-width: 480px;
max-height: 404px;
}
.seven.col-sm-12 img, .eight.col-sm-12 img{
  width:95%;
  height:auto;
}
.address-font{
  font-family: 'Nunito', sans-serif;
  margin-left: 45px;
  font-size:35px
}
.contact-font{
  font-family: 'Nunito', sans-serif;
  margin-left: 45px;
  font-size:35px
}
.phone-div, .email-div, .location-div, .name-div{
  margin-bottom:18px;
}
.contact-details .user-message, .successful-message{
  margin-left:0;
}
.phone-div .number, .email-div .email , .location-text, .company-name, .user-message{
  display: inline;
  margin-left: 10px;
  font-size: 18px;
  font-family: 'Nunito', sans-serif;
}
.successful-message{
  font-size: 18px;
  font-family: 'Nunito', sans-serif;
  margin-top: 18px;
  color: #ffffff;
  padding: 10px;
  border: 1px solid lightgrey;
  background-color: #4CAF50;
  border-radius:2px;
  display:none;
}
.address-details , .contact-details{
  margin-left: 45px;
}
.circle{
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 45px;
  display: block;
  background-color: #ff0099;
}
.contact .circle, .contact-details{
  margin-left: 49px;
  margin-right: 45px;
}
.line{
  background-color:#0095ff;
  width:130px;
  height:3px;
  display: block;
  margin-left: 60px;
  margin-top: -6px;
  border-radius:2px;
}
.address .dot-and-bar, .contact .dot-and-bar{
  margin-bottom: 22px;
}
.fb-circle, .in-circle, .li-circle, .tw-circle, .wh-circle, .te-circle{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid lightgrey;
  display:inline-block;
}
.in-circle, .li-circle, .tw-circle, .wh-circle, .te-circle{
  margin-left:15px;
}
.address-details .social{
 text-align:center;
 margin-bottom: 18px;
}
.fb-circle .fab, .in-circle .fab, .li-circle .fab, .tw-circle .fab, .wh-circle .fab, .te-circle .fab{
  line-height:60px;
  margin:0;
  font-size:20px;
}
.contact .line{
  margin-left:65px;
}
.four, .three, .five{
  width: 100%;
  padding: 0;
}
.client-head, .about-head{
  font-family: 'Nunito', sans-serif;
  font-size: 35px;
  text-align: center;
  margin: 0;
  padding: 0;
  margin-bottom: -8px;
}
.three .circle, .three .line, .four .circle, .four .line, .five .circle, .five .line{
  display: inline-block;
  margin-left: 6px;
}
.three .dot-and-bar, .four .dot-and-bar, .five .dot-and-bar{
  text-align: center;
}
.three .line, .four .line{
  width:70px;
}
.five .line{
  width:92px;
}
.three .dot-and-bar, .five .dot-and-bar{
  padding-bottom:45px;
}
.three.col-sm-12{
  padding-bottom: 35px;
}
.client{
  width: 50%;
  display: inline-block;
  text-align: center;
}
.client img{
  cursor:pointer;
}
.four.col-sm-12{
  text-align:left;
}
.four.col-sm-12{
  background-color: #000000c2;
}
.client-head.col-sm-12{
  color:#ffffff;
  padding-top:50px;
}
.pri.col-sm-4, .sec.col-sm-4, .tre.col-sm-4, .fou.col-sm-4{
  padding:0;
}
.user-form, .userName, .userEmail, .userMessage, .userSubmit{
  margin-top:18px
}
.userName input, .userEmail input, .userMessage textarea{
  width: 100%;
  padding: 5px;
  border-radius: 2px;
  border: 1px solid lightgrey;
  font-size: 18px;
  line-height: 100%;
  -webkit-appearance: none;
}
.userSubmit button{
  width: 100%;
  text-transform: capitalize;
  padding: 5px;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  border:0;
  border-radius:2px;
  background-color: #ff0099;
  color:#ffffff;
}
.userName input::placeholder, .userEmail input::placeholder, .userMessage textarea::placeholder{
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  line-height: 100%;
  color:lightgrey;
  -webkit-appearance: none;
}
.contact.col-sm-6{
  height:auto
}
.companyImage.col-sm-6, .companyDetails.col-sm-6{
  padding:0;
}
.companyText{
  font-size: 18px;
  font-family: 'Nunito', sans-serif;
  margin-left: 60px;
  margin-right: 60px;
}
.imageHolder{
  height:400px;
}
.imageBorder{
  position: absolute;
  width: 320px;
  height: 350px;
  bottom: 25px;
  right: 60px;
  border: 10px solid #0095ff;
}
.mainImage{
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
  position: absolute;
  left: -50px;
  top: -50px;
}
.focus-detail.col-sm-12{
  font-family: 'Nunito', sans-serif;
}
.focus-detail, .left-detail, .right-detail{
  padding:0;
}
.left-detail, .right-detail{
  margin-left: 60px;
  margin-top: 40px;
  width:41%;
}
.companyDetails.col-sm-6{
  width:60%
}
.companyImage.col-sm-6{
  width:40%;
}
.left-text, .right-text{
  font-size: 18px;
  margin-top: 10px;
}
.automation{
  font-family: 'Nunito', sans-serif;
  font-size: 35px;
  text-align: center;
  padding: 0;
  margin-bottom: -11px;
}
.automation-text{
  font-size: 18px;
  font-family: 'Nunito', sans-serif;
  margin-left: 60px;
  margin-right: 60px;
  margin-bottom:40px;
}
.team{
  margin-left:45px;
  text-align:center;
}
.team img{
  width:100px;
}
.member-1, .member-2, .member-3, .member-4{
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #ff0099;
}
.detail-1, .detail-2, .detail-3, .detail-4{
  width: 100px;
  margin-top: 7px;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
}
#user{
  display: inline;
  line-height: 30px;
  font-size: 18px;
  font-family: 'Nunito', sans-serif;
}
#myDropdown{
  display:none
}
#user li{
  list-style-type: none;
}
.signOut{
  background:none;
  border:none;
      width: 100%;
}
#myDropdown{
  text-align: center;
  margin-top: 1px;
  font-size: 16px;
  background-color: #ecf6ff;
  border-right: 1px solid #d3d3d3;
  border-left: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  display:none;
}
.rotated{
  transform:rotate(180deg);
}
a, a:focus, a:hover, a:active{
  text-decoration: none;
  color:#2989ff;
}
.col-sm-2{
  width:20%;
}
.col-sm-10{
  width:80%;
} 
.join_us_on_telegram{
  width: 300px;
  position: fixed;
  bottom: 0;
  right: 50px;
  z-index: 1;
  background-color: #17a4e7;
  border-radius: 10px 10px 0px 0px;
}
.join_us_on_telegram img{
  height:50px;
  margin-top:6px;
  position: absolute;
  left: 0px;
}
.telegram_elem > span{
  height:50px;
  line-height:62px;
  left:60px;
  color:#fff;
  position:absolute;
  font-size:20px
}
.telegram_elem{
  text-align:center;
  position: relative;
  height:60px;
  width:265px;
  left:50%;
  transform: translateX(-50%);
}
@media only screen and (max-width: 600px) {
  #main{
    transform: scale(1);
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9{
    padding:0;
  }
  .col-sm-2{
    width:100%;
  }
  .col-sm-10{
    width:100%;
  }  
  .icon-holder, .contact{
    text-align:center;
  }
  .icon-holder{
    width: 100%;
  }
  .phone-container, .email-container{
    margin-left:0;
    text-align:center;
  }
  .email-container{
    margin-left:20px;
  }
  .contact.col-sm-10{
    margin:0;
    padding:0;
    width: 95%;
    left: 2.5%;
    right: 2.5%;
    margin: 0;
    padding: 0;
  }
  .social-connect{
    margin-top: 10px;
    margin-bottom: 10px;
    border-left: 0;
    line-height: 35px;
    text-align: center;
  }
  .number, .email{
    font-size:15px;
  }
  .one{
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    padding: 0;
  }
  .fa-facebook-f{
  margin-left:0;
  }
  .two{
    height:auto;
    width:100%;
    margin-left: 0;
    margin-right: 0;
  }
  .logo{
    text-align: center;
  }
  .logo-img{
    margin-left:0;
  }
  .menu.col-sm-8{
    height: 60px;
    margin-bottom: 30px;
  }
  .menu ul{
  right: 0;
  margin-right: 0;
  transform: translate(-50%,-50%);
  left: 50%;
  width: 100%;
  text-align: center;
  }
  .menu li {
  display: inline;
  margin: 5px;
  }
  .address-font, .circle, .address-details, .contact-details, .contact-font{
  margin-left:0;
  margin-right:0;
  }
  .address .line{
  margin-left:14px;
  }
  .contact .circle, .contact-details{
  margin-left:2px;
  }
  .contact .line{
  margin-left:16px;
  }
  .phone-div .number, .email-div .email , .location-text, .company-name, .fa-phone, .fa-envelope, .fa-map-marked-alt, .fa-tag{
    font-size: 15px;
  }
  .fa-phone, .fa-envelope, .fa-map-marked-alt, .fa-tag{
    font-size:15px;
  }
  .in-circle, .li-circle, .tw-circle, .wh-circle, .te-circle {
    margin-left: 5px;
  }
  .fb-circle, .in-circle, .li-circle, .tw-circle, .wh-circle, .te-circle {
    width: 50px;
    height: 50px;
  }
  .fb-circle .fab, .in-circle .fab, .li-circle .fab, .tw-circle .fab, .wh-circle .fab, .te-circle .fab {
    line-height: 48px;
    font-size: 18px;
  }
  .address.col-sm-6 , .contact.col-sm-6{
    width:88%;
    left:6%;
    right:6%;
    text-align:left;
  }
  .address.col-sm-6, .contact.col-sm-6{
    height:auto;
  }
  .contact.col-sm-6{
    margin-bottom:18px;
  }
  .contact-font{
    padding-top:27px;
  }
  .userName input, .userEmail input, .userMessage textarea, .userSubmit button, .userName input::placeholder, .userEmail input::placeholder, .userMessage textarea::placeholder{
    font-size:15px;
  }
  .companyText{
    width: 86%;
    margin-left: 7%;
    margin-right: 7%;
  }
  .companyDetails.col-sm-6{
    padding-bottom:45px
  }
  .imageBorder{
    bottom: 0;
    left: 50%;
    top: 50%;
    transform: translate(-46.5%,-50%);
  }
  .mainImage{
    left: -35px;
    top: -35px;
  }
  .companyDetails.col-sm-6{
    width:100%
  }
  .companyImage.col-sm-6{
    width:100%;
  }
  .left-detail, .right-detail, .automation-text {
    width: 86%;
    margin-left: 7%;
    margin-right: 7%;
  }
  .team{
    margin-left: 0;
    margin-right: 0;
    margin-top: 35px;
  }
  .member-1, .member-2, .member-3, .member-4, .detail-1, .detail-2, .detail-3, .detail-4{
    position: relative;
    left:50%;
    transform: translateX(-50%);
  }
  .team .col-sm-3{
    margin-bottom:7%;
    width: 50%;
    display: inline-block;
  }
  .fix{
    position: relative;
    top: -22px;
  }
  .smallBox{
    width:100%;
  }
  .avt.col-sm-4{
    margin-bottom: 40px;
    height: auto;
    max-width: 100%;
    max-height: none;
  }
  .avt.col-sm-4 img{
    width:99%;
  }
  .eight{
    display:none;
  }
  /* .smallBox > .avt.col-sm-4:nth-child(3) {
    display:none;
  } */
  .review-button{
    margin-top:0;
  }
  .join_us_on_telegram{
    right:0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
}
.widget_whatsapp{ position: fixed; width: 100%; height: 100%; z-index: 2; display:none; } .whatsapp_close{ color:#ffffff; background-color:#ff0013; border-radius:50%; width:35px; height:35px; position:absolute; top:24px;line-height:35px; right:10px; font-size:30px; font-family: "Nunito",sans-serif; text-align:center; cursor:pointer; } .gradient{ width:100%; height:100%; background-color: rgba(0, 0, 0, 0.8); } .whatsapp{ width:300px; height:auto; position:absolute; top:50%; left:50%; transform:translate(-50%,-50%) } .whatsapp img{ width:100%; } .upper-widget{height: 280px;overflow: hidden;margin-right: 15px;text-align:center;margin-top:0;margin-left: 15px;margin-bottom: 0;padding-left: 15px;padding-right: 15px;padding-top: 15px;padding-bottom: 0;position: relative;border-radius: 4px;box-shadow: 0 0 6px 0 rgb(121 121 121 / 40%);}::-webkit-scrollbar{width:10px;height:10px}div::-webkit-scrollbar{display:none}::-webkit-scrollbar-track{box-shadow:inset 0 0 5px grey;border-radius:10px}::-webkit-scrollbar-thumb{background:#0095ff;border-radius:10px}::-webkit-scrollbar-thumb:hover{background:#97b3d7}.div-text{color:#929292;padding-left: 15px;padding-right: 15px;padding-bottom: 0;padding-top: 0;position: relative;font-size: 18px;font-family: "Nunito",sans-serif;}.upper-ad-text{margin-top:0px}.top-ad{height: auto;max-height: none;overflow: hidden;margin-right: 15px;text-align:center;margin-top:0;margin-left: 15px;margin-bottom: 0px;padding-left: 0;padding-right: 0;padding-top: 15px;padding-bottom: 15px;position: relative;border-radius: 4px;box-shadow: 0 0 6px 0 rgb(121 121 121 / 40%);}.adcash-top-ad{display:inline-block}.client-name{padding-bottom:50px}